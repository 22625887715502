import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import { heroText, logo, mobileHero, bg, menu, reserveButton } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileHero} alt="Logo" w="90%" mv="10px" />
          <CFView mt="-80px" mb="20px" pulsate>
            <OrderPickupButton />
          </CFView>
          {/* <a href={menu} target="_blank">
            <CFView hover mb="15px">
              <CFImage
                w="100%"
                src={viewMenu}
                alt="Order Pickup Button"
                maxWidth="260px"
              />
            </CFView>
          </a> */}
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          w="100%"
          zIndex={90}
          image={`url(${bg}) bottom / cover no-repeat`}
          column
          justifyBetween
          alignCenter
        >
          <CFImage src={logo} alt="Logo" w="220px" mv="10px" />
          <CFImage src={heroText} alt="Logo" w="65%" mv="20px" />
          <CFView row center mv="15px">
            <CFView pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
